// Componant: Button
.btn:not(.hamburger, .btn-back-skills, .btn-back-inline, .btn-edit, .min-w-auto){
  @include media-breakpoint-down(sm){
    width: 100%;
  }
}
.btn-clear{
    @include button(rgba($dark, .5), transparent, transparent);
}
.btn-outline-primary{
    color: $black;
    &:hover{
        color: $white;
    }
    &:focus{
        color: $black;
    }
}

.btn-register{
    margin-top: 40px;
    font-weight: $font-weight-bold;
    @extend .btn-outline-light;
    @extend .text-uppercase;
    font-size: em(14);
    color: #ffffff;
    border-color: #ffffff;
    min-width: 215px;
}
.btn-signup{
    padding: 1rem 2.65rem;
    font-weight: $font-weight-bold;
    @extend .btn-outline-primary;
    @extend .text-uppercase;
    min-width: 215px;
    &:disabled{
      &:hover{
        color: $primary !important;
      }
    }
}
.btn-outline-primary{
    border-color: $pink-light;
    color: $gray-800;
    &:disabled{
        &:hover{
            color: $primary !important;
        }
    }
}
.btn-200{
    min-width: 200px;
}
.btn-250{
  min-width: 250px;
}
.btn-skill{
    font-weight: $font-weight-bold;
    @extend .btn-outline-primary;
    @extend .text-uppercase;
    color: $gray-800;
    min-width: 250px;
}
.input-group-append{
    .btn{
        min-width: unset !important;
    }
}


.arrow-link {
    color: $pink;
    font-weight: $font-weight-bold;
    position: relative;
    padding: 0 35px 0 0;
    display: inline-block;
    &:hover {
        color: $pink;
    }
    &:after {
        content: "";
        pointer-events: none;
        position: absolute;
        top: 7px;
        right: 10px;
        width: 15px;
        height: 15px;
        background: url(../images/svg/arrow-right-fill.svg) 0 0 no-repeat;
        background-size: 15px;
        @include default_transition(0.3s, ease-in-out);
    }
    &:hover:after {
        right: 0;
    }
}
