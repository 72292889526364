::selection {
  color:darken($white,2%);
  background-color: darken($primary,10%);
}

::-webkit-scrollbar {
  height: 5px;
  width: 5px;
  background-color: $primary;
}

::-webkit-scrollbar-thumb {
  background-color: darken($primary,10%);
  -webkit-border-radius: 20px;
  height: 100px;
}

::-webkit-scrollbar-corner {
  background: #000;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body{
  font-size: 16px;
  @include media-breakpoint-down(lg) {
    font-size: 15px;
  }
}
.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
  color: $heading-color;
  line-height: 1.125;
}
p{
  line-height: 24px;
}
button{
  cursor: pointer;
}

.btn{
  padding: .75rem 2.085rem;
  text-transform: inherit;
  font-size: em(13);
  border-width: 2px;
  font-weight: 700;
}
.btn-lg{
  padding: 1.075rem 2.33rem;
}

.box-shadow{
  @include box-shadow(0, 0, 20px, 0, rgba($dark, 0.1));
}

%box-shadow{
  @include box-shadow(0, 11px, 40px, 0, rgba($black, 0.04));
}

.bg-transparent{
  background-color: transparent;
}

.row-10{
  margin-right: ($grid-gutter-width-10 / -2);
  margin-left: ($grid-gutter-width-10 / -2);
  &
  {
    [class*="col-"], [class*="col"] {
      padding-right: ($grid-gutter-width-10 / 2);
      padding-left: ($grid-gutter-width-10 / 2);
    }
  }
}
.container-15{
  padding-right: ($grid-gutter-width-15 / 2);
  padding-left: ($grid-gutter-width-15 / 2);
}
.row-15{
  margin-right: ($grid-gutter-width-15 / -2);
  margin-left: ($grid-gutter-width-15 / -2);
  &
  {
    [class*="col-"] {
      padding-right: ($grid-gutter-width-15 / 2);
      padding-left: ($grid-gutter-width-15 / 2);
    }
  }
}
.row-20{
  margin-right: ($grid-gutter-width-20 / -2);
  margin-left: ($grid-gutter-width-20 / -2);
  &
  {
    [class*="col-"] {
      padding-right: ($grid-gutter-width-20 / 2);
      padding-left: ($grid-gutter-width-20 / 2);
    }
  }
}

.error-message {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}
.react-datepicker-wrapper{
  @extend .w-100;
}
.react-datepicker__input-container{
  @extend .w-100;
}
.ui.selection.dropdown{
  height: calc(3.786rem + 4px) !important;
}
@media (min-width: 1200px){
  .nannies-list{
    .col-xl-3{
      flex: 0 0 20%;
      max-width: 20%;
    }
  }
}
.btn-lg, .btn-group-lg > .btn {
  padding: 1.215rem 2.33rem;
}
.text-26-33{
  font-size: em(26);
  line-height: 33px;
  @include media-breakpoint-down(md) {
    font-size: em(20);
    line-height: 28px;
  }
}
.text-note{
  color: #9d9e9f;
  font-size: em(14);
}
.Toastify__toast--success{
  background-color: #d69996 !important;
}
.form-placeholder{
  &.StripeElement--empty{
    &>div{
      top: 5px;
    }
  }
  &.StripeElement--empty.StripeElement--focus{
    &>div{
      top: 5px;
    }
  }
   &.StripeElement--focus{
    &>div{
      top: 1px;
    }
  }
  &.StripeElement--complete, &.StripeElement--invalid{
    &>div{
      top: 1px;
    }
  }
}
/*.form-group-30{
  .form-group{
    margin-bottom: 30px;
    .form-label{
      @extend .d-block;
      @extend .text-center;
      @extend .mb-3;
    }
  }
}*/
.text-hover-primary{
  &:hover{
    color: $primary;
  }
}
