// Componant:
.page-our-difference{
    p{
        line-height: 20px;   
        margin-bottom: 20px;
    }
    .different-list{
        padding: 100px 0;
        @include media-breakpoint-down(xl) {
            padding:5rem 0;
        }
        @include media-breakpoint-down(sm) {
            padding:3rem 0;
        }
        p{
            span{
                display: block;
                color: $primary;
            }
        }
    }
    hr{
        margin-bottom: 0;  
    }
}