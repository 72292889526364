// Componant: Page Home
.page-contact{
    .page-banner{
        background-color: #fdf9f9;
        @extend %flexbox;
    }
    .section{
        &:not(.section-newsletter){
            .form-control{
                @extend .cus-control;
            }
        }
    }
    .form-group{
        margin-bottom: 30px;   
    }
    .contact-info{
        .contact-title{
            font-size: em(20);
            font-weight: $font-weight-regular;
            color: $primary;
            text-transform: uppercase;
            margin-bottom: 10px;
        }   
        .contact-desc{
            font-size: em(16);
            margin-bottom: 45px;
            color: #9d9e9f;
            a{
                color: inherit;
            }
            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }
        }
    }
    textarea{
        resize: none;
    }
}