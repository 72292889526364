// Componant: select2
.ui.selection.dropdown {
  font-size: 1rem;
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 57px;
  user-select: none;
  -webkit-user-select: none;
  border-width: 2px;
  border-style: solid;
  border-color: transparent;
  padding-left: 1.5rem;
  padding-right: 2.5rem;
  min-width: auto;
  color: #555657;
  @include box-shadow(16px, 19px, 41.85px, 3.15px, rgba($black, 0.04));
  @extend .d-flex;
  @extend .align-items-center;
  @extend .flex-wrap;
  .default{
    margin: 0;
    color: $black !important;
    font-size: 1rem;
    opacity: 0.3;
    @include media-breakpoint-down(xs) {
      font-size: 1rem;
    }
  }
  @include media-breakpoint-down(xs) {
    font-size: 1rem;
  }
  &:hover {
    -webkit-box-shadow: 16px 19px 41.85px 3.15px rgba(0, 0, 0, 0.04) !important;
    -moz-box-shadow: 16px 19px 41.85px 3.15px rgba(0, 0, 0, 0.04) !important;
    box-shadow: 16px 19px 41.85px 3.15px rgba(0, 0, 0, 0.04) !important;
    border-color: transparent;
  }
  &:focus {
    border-color: $primary;
  }
  i.delete{
    @extend .font-muli;
    color: $primary;
    &:before{
      content: 'x';
    }
  }
  & > .dropdown {
    &.icon {
      top: 1.2em;
      &:before {
        content: '';
        border: solid rgba(0, 0, 0, 0.3);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(42deg);
        top: 1.2em;
        height: 0;
        right: 20px;
        position: absolute;
        width: 0;
      }
    }

    &:focus {
      border-color: $primary;
    }
  }
  &.active {
    &.dropdown {
      border-color: $primary;
      .menu {
        .item {
          padding: .78571429rem 1.5rem !important;
          font-size: 1rem;
        }
      }
      &:hover{
        border-color: $primary;
        .menu{
          border-color: $primary;
          .item{
            font-weight: $font-weight-normal;
          }
        }
      }
      &.icon {
        &:before {
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
        }
      }
    }
    &:focus {
      border-color: $primary;
    }
  }
  &.multiple{
    min-height: 57px;
    height:auto !important;
    .ui.label{
      @include border-radius(2px);
      border-color: rgba($primary, 0.5);
      border-width: 2px;
      padding: 7px 9px;
      background-color: #fdf9f9;
      font-weight: $font-weight-normal;
    }
    .menu{
      border-color: $primary;
      .selected{
        background-color: rgba($primary, 0.3);
      }
      .item{
        &:hover{
          background-color: $primary;
        }
      }
    }
  }
  .selected{
    background-color: rgba($primary, 0.3);
  }
  .item{
    font-size: 1rem;
    &:hover{
      background-color: $primary;
    }
  }
}

.select2 {
  .select2-selection--single {
    box-sizing: border-box;
    cursor: pointer;
    display: block;
    height: 57px;
    user-select: none;
    -webkit-user-select: none;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    padding-left: 1.3rem;
    padding-right: 1.3rem;
    @include box-shadow(16px, 19px, 41.85px, 3.15px, rgba($black, 0.04));
    .select2-selection__rendered {
      font-size: 1rem;
      color: $heading-color;
      display: block;
      padding-left: 8px;
      padding-right: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 57px;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
    .select2-selection__clear {
      position: relative;
    }
    .select2-selection__placeholder {
      color: $black;
      font-size: 1rem;
      opacity: 0.3;
    }
    .select2-selection__arrow {

    }
    .select2-selection__arrow {
      height: 57px;
      position: absolute;
      top: 1.2em;
      right: 22px;
      width: 20px;

      b {
        border: solid $primary;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(42deg);
        margin-top: -4px;
      }
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
  &:focus {
    outline: none;
    box-shadow: none;
  }
  &.select2-container {
    width: 100% !important;
  }
  &.select2-container--open {
    .select2-selection--single {
      .select2-selection__arrow {
        b {
          border: solid $primary;
          border-width: 0 2px 2px 0;
          transform: rotate(225deg);
          -webkit-transform: rotate(225deg);
        }
      }
    }
  }
}

.select2-dropdown {
  border-width: 2px;
  border-color: transparent;
  @include box-shadow(16px, 19px, 41.85px, 3.15px, rgba($black, 0.04));
}

.select2-container--default {
  .select2-results__option--highlighted[aria-selected] {
    background-color: $primary;
    color: $white;
  }
  .select2-search--dropdown {
    .select2-search__field {
      border-color: transparent;
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }
  .select2-results__options {
    .select2-results__option {
      color: $heading-color;
      &[aria-selected=true] {
        background-color: rgba($primary, 0.3);
      }
    }
  }
  .select2-selection--multiple {
    @include box-shadow(16px, 19px, 41.85px, 3.15px, rgba($black, 0.04));
    position: relative;
    border-color: transparent;
    min-height: 51px;
    padding-right: 15px;
    border-width: 2px !important;
    .select2-selection__rendered {
      &:after {
        content: '';
        border: solid rgba($black, 0.3);
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(42deg);
        top: 20px;
        height: 0;
        right: 26px;
        position: absolute;
        width: 0;
      }
      li {
        @include border-radius(2px);
        border-color: rgba($primary, 0.5);
        border-width: 2px;
        padding: 7px 9px;
      }
      .select2-search__field {
        color: $black;
        font-size: 16px;
        opacity: 0.4;
        padding-left: 10px;
      }
    }
    .select2-selection__choice {
      margin-top: 6px;
      @extend .bg-lightdark;
    }
    .select2-selection__choice__remove {
      float: right;
      margin-right: 0px;
      margin-left: 7px;
      color: $primary;
    }
  }

  &.select2-container--focus {
    .select2-selection--multiple {
      border-color: $primary;
    }
  }
}
