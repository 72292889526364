// Page: Authentication Pages
.page-auth {
  .section  {
    @include media-breakpoint-down(md) {
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }
  margin-bottom: 60px;
  .heading {
    margin-top: 0.5rem !important;
    font-size: em(40);
    position: relative;
    margin-bottom: 10px;
    .btn-back {
      @extend .btn-clear;
      position: absolute;
      left: 0;
      padding: 0;
      top: -8px;
      min-width: auto !important;
      &:not(.btn-back-inline) {
        @include media-breakpoint-down(sm) {
          position: relative;
          min-width: 100% !important;
          text-align: left;
        }
      }
    }
    & + p {
      margin-bottom: 35px;
      @include media-breakpoint-down(sm) {
        margin-bottom: 20px;
      }
    }
    @include media-breakpoint-down(sm) {
      font-size: em(20) !important;
      .btn-back {
        top: -5px;
      }
    }
  }
  .form-control {
    @extend .cus-control;
    padding: 1.2rem 1.5rem;
  }
  p {
    font-size: 14px;
    color: #9d9e9f;
    position: relative;
    z-index: 1;
  }
  .link-auth {
    // font-size: em(14);
    // font-weight: $font-weight-semibold;
    font-size: 14px;
    padding-right: 15px;
  }
  .btn {
    padding: 0.893rem 2.085rem;
    min-width: 215px;
  }
  .btn:not(.btn-clear) {
    text-transform: uppercase;
    font-weight: $font-weight-bold;
  }
  footer {
    margin-top: 150px;
    @include media-breakpoint-down(md) {
      margin-top: 3rem;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 1.5rem;
    }
  }
  .email-icon {
    margin: -100px 0 -110px;
    @include media-breakpoint-down(sm) {
      margin: -50px 0 -80px;
    }
  }
  .input-group {
    .btn {
      object {
        cursor: pointer;
        z-index: -1;
        position: relative;
      }
    }
  }
  .profile-pic {
    margin-top: 30px;
    .pic-heading {
      font-size: em(12);
      color: $heading-color;
      font-weight: $font-weight-semibold;
    }
    .pic-card {
      margin: 20px 0 30px;
      @extend %box-shadow;
      width: 200px;
      height: 200px;
      @include border-radius(50%);
      background-color: $white;
      @extend %flexbox-align-c;
      .profile-avatar {
        max-width: 200px;
        width: 200px;
        height: 200px;
        cursor: pointer;
        @extend %flexbox-align-c;
        @include border-radius(50%);
        img {
          width: 57px;
          height: 57px;
          &.file-selected {
            width: 200px;
            height: 200px;
            @include border-radius(50%);
            object-fit: cover;
          }
        }
      }
    }
  }
  .custom-control {
    .custom-control-label {
      padding-left: 35px;
      line-height: 17px;
      color: #9d9e9f;
      font-size: em(14);
      font-weight: $font-weight-light;
      margin-bottom: 5px;
      span {
        font-weight: $font-weight-semibold;
      }
      &:after {
        @extend %box-shadow;
        left: -1.7rem;
        top: 1px;
      }
      &:before {
        left: -1.7rem;
        top: 1px;
      }
      @include media-breakpoint-down(xs) {
        padding-left: 25px;
      }
    }
  }
  .select-skills {
    .select-item-chk {
      display: none;
      &:checked + .select-skills-item {
        border-color: $primary;
        @include transition(all 0.2s ease-in-out);
        background-image: url(../images/svg/tick.svg);
      }
    }
    .select-skills-item {
      background-image: none;
      background-size: 18px;
      background-position: top 9px right 9px;
      background-repeat: no-repeat;
      cursor: pointer;
      @include transition(all 0.2s ease-in-out);
      border-width: 2px;
      border-style: solid;
      border-color: transparent;
      @include border-radius(5px);
      padding: 27px 20px;
      min-height: 167px;
      height: calc(100% - 30px);
      background-color: #ffffff;
      margin-bottom: 30px;
      flex-direction: column;
      @extend %flexbox-align-c;
      @extend %box-shadow;
      .select-skills-icon {
        max-height: 45px;
        max-width: 80%;
      }
      .select-skills-name {
        line-height: 18px;
        display: block;
        text-align: center;
        padding-top: 10px;
        color: $heading-color;
        font-size: em(14);
        font-weight: $font-weight-light;
      }
      &:hover {
        border-color: $primary;
      }
    }
  }
}
