// Componant: Footer
.landing-pages{
  footer{
    border-top: none;
    padding: 28px 0 70px 0 ;
  }
}
footer{
  border-top: 1px solid #eaeaea;
  position:relative;
  padding: 70px 0;
  color: $body-color;
  @media (max-width: 767px){
    padding: 50px 0;
  }
  @media (max-width: 576px){
    padding: 30px 0;
  }
  @include media-breakpoint-down(sm) {
    padding-left:1rem;
    padding-right:1rem;
  }
  background-color: $white;
  a{
    color: $body-color;
    line-height: 20px;
    font-size: em(13);
    &:hover{
      color: $primary !important;
    }
  }
  p{
    font-size: em(13);
  }
  .footer-item-list{
    margin-bottom: 15px;
  }
  .footer-item{
    margin-bottom: 20px;
    @include media-breakpoint-down(lg) {
      text-align:left !important;
    }
    .footer-heading{
      margin: 25px 0 17px;
      text-transform: uppercase;
      font-size: em(14);
      font-weight: $font-weight-bold;
    }
    p{
      line-height: 18px;
    }
    .list-inline-item{
      line-height: 18px;
    }
    &-menu{
      .list-inline-item{
        margin-right: 0;
      }
    }
  }
  .social-icon{
    /*margin-bottom: 10px;*/
    @include default_transition(0.3s, ease-in-out);
    width: 42px;
    height: 42px;
    border:1px solid rgba(#595959, 0.2);
    @include border-radius(50%);
    @extend %flexbox-align-c;
    svg{
      @include default_transition(0.3s, ease-in-out);
      fill: rgba($black, 0.4);
    }
    &:hover{
      border-color: $primary;
      box-shadow: 0 0 0 1px $primary, 0 0 0 2px $primary;
      svg{
        fill: $primary;
      }
    }
  }
  .text-copyright{
    font-size: em(13);
    padding-top: 25px;
    margin-bottom: 0;
    @include media-breakpoint-down(sm) {
      padding-top: 0;
    }
  }
}
.col-footer{
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
  @include media-breakpoint-up(lg) {
    flex: 0 0 20%;
    max-width: 20%;
  }
  @include media-breakpoint-down(md) {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }
  @include media-breakpoint-down(sm) {
    flex: 0 0 50%;
    max-width: 50%;
	&:last-child{
	 flex: 0 0 100%;
     max-width: 100%;
	}
  }
}