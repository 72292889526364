// Componant: Page Home
.page-home {
  .page-banner {
    @extend %flexbox;
    .banner-caption {
      .search {
        & > .col-12 {
          @extend .px-0;
        }
      }
    }
  }
  .page-heading {
    font-size: em(60);
  }
  .sectionquote {
    @extend %flexbox-align-c;
    min-height: 560px;
    @extend .font-baskervilleitalic;
    color: $white;
    p {
      font-size: em(27);
      line-height: 42px;
      display: inline;
    }
    .icon-quote {
      @extend .font-muli;
      font-size: em(150);
      color: $primary;
      opacity: 0.7;
      display: inline-block;
      line-height: 0;
    }
    .icon-q-start {
      vertical-align: top;
      margin-top: -20px;
      @include rotatex(180deg);
      padding: 0px 7px 0 0;
    }
    .icon-q-end {
      @include rotate(-180deg);
      vertical-align: top;
      margin-top: -11px;
    }
    @include media-breakpoint-down(md) {
      p {
        line-height: 30px;
        font-size: em(20);
      }
      .icon-quote {
        margin-top: -12px;
        font-size: em(100);
      }
      .icon-q-end {
        margin-top: -5px;
      }
    }
    @include media-breakpoint-down(lg) {
      min-height: inherit;
    }
  }
  .skills-list {
    margin-top: 10px;
    .skills-item {
      display: inline-block;
      position: relative;
      background: none;
      border: none;
      text-align: center;
      /*margin-bottom: 30px;*/
      border: 1px solid transparent;
      @include transition(all 0.3s ease-in-out);
      width: 100%;
      .skills-icon {
        max-height: 45px;
      }
      .skills-name {
        font-size: em(16);
        padding: 12px 0;
        margin-bottom: 0;
        color: $black;
        letter-spacing: 1px;
        @extend .text-uppercase;
      }
      .skills-info {
        font-size: em(14);
        line-height: 18px;
        margin-bottom: 5px;
      }
    }

    .skills-effect {
      display: block;
      padding: 45px 25px;
      @include media-breakpoint-down(sm) {
        padding: 1rem;
      }
    }
    .skills-item {
      &:hover {
        border-color: #d9e1e5;
      }
    }
  }
  .btn-nannies {
    font-weight: $font-weight-bold;
    @extend .btn-outline-primary;
    @extend .text-uppercase;
    color: #6b6b6c;
  }
  .nannies-list {
    margin: 65px 0 35px;
    @include media-breakpoint-down(sm) {
      margin: 1rem 0;
    }
    .nannies-item {
      margin-bottom: 30px;
      text-align: center;
      .nannies-name {
        font-size: em(16);
        margin-bottom: 0;
        color: $black;
        letter-spacing: 1px;
        @extend .text-capitalize;
        line-height: 26px;
        font-weight: $font-weight-bold;
        color: $black;
      }
      .nannies-info {
        font-size: em(12);
        @extend .text-uppercase;

        img:last-child {
          margin-right: 0 !important;
        }
      }
      .nannies-avatar {
        border: 25px solid $white;
        /*border: 25px solid $primary;*/
        @include media-breakpoint-down(sm) {
          border-width: 15px;
          margin-bottom: 10px;
        }
        @include border-radius(50%);
        object-fit: cover;
        margin-bottom: 20px;
        width: 242px;
        height: 242px;
        @extend %box-shadow;
        @include transition(all 0.4s ease-in-out);

        @media screen and (max-width: 1440px) and (min-width: 1280px) {
          width: 205px;
          height: 205px;
        }

        @media (max-width: 1280px) {
          width: 205px;
          height: 205px;
        }
      }
      .location-icon {
        margin-top: 20px;
        display: block;
        svg {
          @include transition(all 0.4s ease-in-out);
          width: 17px;
          height: 17px;
          fill: #d5d5d6;
        }
      }
      .nannies-city {
        margin-top: 5px;
        font-size: em(12);
        color: rgba($black, 0.5);
        font-weight: $font-weight-regular;
        text-transform: capitalize;
      }
      &:hover {
        .nannies-avatar {
          border-color: $primary;
          @include default_transition(0.3s, ease-in-out);
        }

        .location-icon {
          svg {
            /*@include transition(all 0.4s ease-in-out);*/
            fill: $primary;
          }
        }
      }
    }
  }
  .become-nanny {
    padding: 100px 0;
    .heading {
      text-transform: inherit;
    }
    .sub-heading {
      font-weight: $font-weight-regular;
      padding: 15px 0;
      margin-bottom: 0;
      font-size: em(18);
    }
    p {
      margin-bottom: 20px;
    }
    .btn-register {
      min-width: 250px;
    }
    @include media-breakpoint-down(md) {
      background-color: rgba(255, 255, 255, 0.6);
      .btn-register {
        color: $primary;
        border-color: $primary;
      }
    }
    @include media-breakpoint-down(sm) {
      padding-top: 3rem;
      padding-bottom: 3rem;
      padding-left: 30px;
      padding-right: 30px;
      .parallax {
        opacity: 0.5;
      }
    }
  }
  .section-newsletter {
    .heading {
      text-transform: inherit;
      @include media-breakpoint-down(sm) {
      }
    }
  }
  .process-list {
    .process-item {
      margin-bottom: 60px;
      @include media-breakpoint-down(sm) {
        text-align: center;
      }
    }
    .media {
      img {
        width: 70px;
        @include media-breakpoint-down(sm) {
          width: 50px;
        }
      }
    }
  }
  .section-instagram {
    margin-right: -60px;
    margin-left: -60px;
    @include media-breakpoint-down(xl) {
      margin-right: -30px;
      margin-left: -30px;
    }
    @include media-breakpoint-down(sm) {
      margin-right: -1rem;
      margin-left: -1rem;
    }
    .nanny-instagram-list {
      width: 100%;
      height: 240px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      text-align: center;
    }
    .col-item {
      width: 240px;
      height: 240px;
      display: inline-block;
      vertical-align: middle;
    }
  }
}
