//
// Customize bootstrap variables to match
// look and feel of the theme
//-----------------------------------------------------

//
// Color system
//

$white:    #fff !default;
$gray-100: #fdf9f9 !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #555657 !default;
$gray-900: #212529 !default;
$black:    #000000 !default;

$grays: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$grays: map-merge(
(
"100": $gray-100,
"200": $gray-200,
"300": $gray-300,
"400": $gray-400,
"500": $gray-500,
"600": $gray-600,
"700": $gray-700,
"800": $gray-800,
"900": $gray-900
),
$grays
);


$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e6aead !default;
$pink-light:    #f1d1cc !default;
$red:     #dc3545 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #28a745 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
(
"blue":       $blue,
"indigo":     $indigo,
"purple":     $purple,
"pink":       $pink,
"red":        $red,
"orange":     $orange,
"yellow":     $yellow,
"green":      $green,
"teal":       $teal,
"cyan":       $cyan,
"white":      $white,
"gray":       $gray-600,
"gray-dark":  $gray-800
),
$colors
);

$primary:       $pink !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-800 !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
(
"primary":    $primary,
"secondary":  $secondary,
"success":    $success,
"info":       $info,
"warning":    $warning,
"danger":     $danger,
"light":      $light,
"dark":       $dark
),
$theme-colors
);

$body-bg:    #ffffff;
$body-color: #575555 !important;
$heading-color: $gray-800;
$subheading-color: $body-color;
$base-shadow-color: #005595;
$nav-base-color: #a0a0a0;

// Components
//
// Define common padding and border radius sizes and more.

$border-radius:              .25rem !default;


$font-family-sans-serif:     'Muli', sans-serif;
$font-family-heading:        'baskvillwebfont';
$link-color: $primary;
$headings-font-weight: bold;

$font-weight-light:          300 !default;
$font-weight-regular:        400 !default;
$font-weight-semibold:       600 !default;
$font-weight-bold:           700 !default;

// Custmize form component styles

$input-border-radius: 4px;
$input-border-width: 2px;
$input-border-color: #eaeaea;
$input-focus-border-color: $primary;
$input-btn-border-width: 1px;
$input-btn-focus-box-shadow: none;
$input-btn-focus-width: 0;

// Forms
$input-color: $dark;
$input-btn-padding-y:         .887rem !default;
$input-btn-padding-x:         1.2rem !default;

$custom-control-indicator-checked-color:        $white !default;
$custom-control-indicator-checked-bg: $primary;
$custom-control-indicator-size:         2.313rem !default;
$custom-control-indicator-bg: $white;
$custom-checkbox-indicator-border-radius:       5px !default;
$custom-radio-indicator-icon-checked:           url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

// Links

// Style anchor elements.
$link-hover-decoration:     none !default;

// card
$card-border-radius: $border-radius;
$card-color: $dark;
$card-spacer-y:                     1.3rem !default;
$card-spacer-x:                     1.2rem !default;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-gutter-width:           30px !default;
$grid-gutter-width-20:           20px;
$grid-gutter-width-15:           15px;
$grid-gutter-width-10:           10px;

$grid-breakpoints: (
xs: 0,
sm: 576px,
md: 768px,
lg: 992px,
xl: 1200px,
xll: 1441px
) !default;

// Grid containers
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
sm: 540px,
md: 720px,
lg: 960px,
xl: 1140px,
xll: 1280px
) !default;


// Padding applied to the modal body
$modal-inner-padding:               1.2rem 2rem !default;
$modal-dialog-margin:               .5rem !default;
$modal-dialog-margin-y-sm-up:       1.75rem !default;
$modal-content-border-color:        transparent !default;
$modal-backdrop-bg:                 $black !default;
$modal-backdrop-opacity:            .8 !default;
$modal-header-padding:              2rem !default;

$modal-lg:                          880px !default;
$modal-md:                          560px !default;
$modal-sm:                          440px !default;
