html,
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Componant: Component
section {
  &.page-banner {
    background-color: #fdf9f9 !important;
  }
  .banner-caption {
    .banner-text {
      text-align: center;
      padding: 0 2rem;
      p a {
        color: $body-color;
        @include default_transition(0.3s, ease-in-out);
        &:hover {
          color: $pink;
        }
      }
      @include media-breakpoint-down(sm) {
        padding: 0;
      }
    }
    span:not(.text) {
      color: $primary;
      font-size: em(20);
      text-transform: uppercase;
      font-weight: $font-weight-bold;
      // letter-spacing: 5.5px;
      margin-bottom: 12px;
      display: block;
      @include media-breakpoint-down(sm) {
        font-size: em(16);
      }
    }
    .react-tel-input {
      span {
        color: inherit;
        font-size: inherit;
        text-transform: inherit;
        font-weight: inherit;
        letter-spacing: inherit;
        margin-bottom: inherit;
        display: inline-block;
      }
    }
    .ls-auto {
      letter-spacing: normal;
      font-size: inherit;
    }
    p {
      /*line-height: 24px;*/
      margin-bottom: 25px;
    }
  }
  .heading {
    line-height: normal;
    font-family: $font-family-heading;
    font-size: em(44);
    font-weight: $font-weight-regular;
    color: $heading-color;
    margin-bottom: 0;
    text-transform: capitalize;
  }
  .sub-heading {
    font-size: em(20);
    font-weight: $font-weight-semibold;
    color: $subheading-color;
    line-height: 26px;
    &.text-white {
      color: $white !important;
    }
    @include media-breakpoint-down(md) {
      font-size: em(18);
    }
  }
  .page-heading {
    font-size: em(43.75);
    text-transform: uppercase;
  }
  p {
    font-size: em(16);
  }
  &.section {
    padding: 150px 0;
    &.sectionquote {
      @include media-breakpoint-down(sm) {
        padding: 50px 0;
      }
    }
    @include media-breakpoint-down(xl) {
      padding: 80px 0;
      .heading {
        font-size: em(42);
      }
    }
    @include media-breakpoint-down(md) {
      .heading {
        font-size: em(32);
      }
    }
    @include media-breakpoint-down(sm) {
      padding: 50px 0;
    }
    @include media-breakpoint-down(xs) {
      padding: 40px 0;
    }

    &.top-nannies {
      @include media-breakpoint-down(sm) {
        margin-bottom: 50px;
      }
      @include media-breakpoint-down(xs) {
        margin-bottom: 40px;
      }
    }
  }
}
/*
.bg-light {
  background-color: #fdf9f9 !important;
}*/

.bg-auth,
.bg-lightdark {
  background-color: #fdfafa;
}

.border-c-light {
  border-color: #eaeaea !important;
}

.bg-light-5 {
  background-color: #fafafc;
}

// Country Select box
.intl-tel-input {
  width: 100%;
}

// Loaders

.loading-overlay {
  z-index: 2000;
  position: fixed;
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.7);
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h2 {
    padding: 0 20px;
    @include media-breakpoint-down(sm) {
      font-size: 22px;
    }
    @include media-breakpoint-down(xs) {
      font-size: 18px;
    }
  }
}

.sk-circle {
  margin: 0 auto 50px auto;
  width: 40px;
  height: 40px;
  position: relative;
  .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    &:before {
      content: "";
      display: block;
      margin: 0 auto;
      width: 15%;
      height: 15%;
      background-color: $primary;
      border-radius: 100%;
      -webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
      animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
    }
  }
  .sk-circle2 {
    -webkit-transform: rotate(30deg);
    -ms-transform: rotate(30deg);
    transform: rotate(30deg);
  }
  .sk-circle3 {
    -webkit-transform: rotate(60deg);
    -ms-transform: rotate(60deg);
    transform: rotate(60deg);
  }
  .sk-circle4 {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  .sk-circle5 {
    -webkit-transform: rotate(120deg);
    -ms-transform: rotate(120deg);
    transform: rotate(120deg);
  }
  .sk-circle6 {
    -webkit-transform: rotate(150deg);
    -ms-transform: rotate(150deg);
    transform: rotate(150deg);
  }
  .sk-circle7 {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .sk-circle8 {
    -webkit-transform: rotate(210deg);
    -ms-transform: rotate(210deg);
    transform: rotate(210deg);
  }
  .sk-circle9 {
    -webkit-transform: rotate(240deg);
    -ms-transform: rotate(240deg);
    transform: rotate(240deg);
  }
  .sk-circle10 {
    -webkit-transform: rotate(270deg);
    -ms-transform: rotate(270deg);
    transform: rotate(270deg);
  }
  .sk-circle11 {
    -webkit-transform: rotate(300deg);
    -ms-transform: rotate(300deg);
    transform: rotate(300deg);
  }
  .sk-circle12 {
    -webkit-transform: rotate(330deg);
    -ms-transform: rotate(330deg);
    transform: rotate(330deg);
  }
  .sk-circle2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
  }
  .sk-circle3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
  .sk-circle4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
  }
  .sk-circle5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
  }
  .sk-circle6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
  }
  .sk-circle7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
  }
  .sk-circle8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
  }
  .sk-circle9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
  }
  .sk-circle10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
  }
  .sk-circle11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
  }
  .sk-circle12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
  }
}

@-webkit-keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes sk-circleBounceDelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.form-group-btn {
  .form-control {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.secion-founder {
  .quotemsg {
    @extend .font-baskervilleitalic;
    font-size: em(18);
    display: inline;
    line-height: 33px;
    @include media-breakpoint-down(xl) {
      font-size: em(16);
      line-height: 26px;
    }
  }
  .icon-quote {
    @extend .font-muli;
    font-size: em(90);
    color: $primary;
    opacity: 0.7;
    display: inline-block;
    line-height: 0;
  }
  .icon-q-start {
    // position: absolute;
    // vertical-align: top;
    // margin-top: -10px;
    // @include rotatex(180deg);
    // padding: 0px 7px 0 0;
    background-image: url(../images/svg/98868.svg);
    background-position: top 4px center;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin-right: 5px;
  }
  .icon-q-end {
    // @include rotate(-180deg);
    // vertical-align: top;
    // margin-top: -5px;
    background-image: url(../images/svg/98869.svg);
    background-position: top 5px center;
    background-size: 18px 18px;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px;
    margin-left: 5px;
  }
  .quote-divider {
    display: inline-block;
    width: 47px;
    @include media-breakpoint-down(lg) {
      width: 35px;
    }
  }
}

.process-list {
  .processitem {
    margin-top: 50px;
    .media-body {
      position: relative;
      &:after {
        content: "";
        width: 50px;
        height: 1px;
        background-color: #f7b7ae;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }
    .process-number {
      width: 44px;
      height: 44px;
      @include border-radius(50%);
      background-color: #f7b7ae;
      font-size: em(14);
      color: $white;
      text-align: center;
      line-height: 44px;
    }
    h5 {
      font-size: em(20);
      font-weight: $font-weight-semibold;
      margin-bottom: 0.75rem;
      // line-height: 0.8;
    }
    p {
      margin-bottom: 25px;
    }

    @include media-breakpoint-down(sm) {
      margin-top: 30px;
    }
  }
}
.mb-60 {
  margin-bottom: 60px;
}
.section-applynow {
  margin-bottom: 60px;
  .heading {
    text-transform: uppercase;
    font-size: em(36) !important;
    margin-bottom: 20px !important;
  }
  @include media-breakpoint-down(sm) {
    .heading {
      font-size: em(24) !important;
      @include media-breakpoint-down(sm) {
        font-size: em(20) !important;
      }
    }
  }
  .btn-outline-primary {
    text-transform: uppercase;
    min-width: 250px;
    @extend .btn-lg;
    @extend .px-5;
    @extend .mt-4;
    font-size: em(13);
  }
}

.heading-36 {
  font-size: em(36) !important;
  margin-bottom: 6px !important;
  @include media-breakpoint-down(sm) {
    font-size: em(24) !important;
  }
}

.list-icon {
  .list-group-item {
    border: 0;
    background-color: transparent;
    background-image: url(../images/svg/tick-inside-circle.svg);
    background-position: left top 5px;
    background-size: 19px 19px;
    background-repeat: no-repeat;
    padding: 0 0 0 40px;
    margin-bottom: 28px;
    font-size: em(18);
    line-height: 24px;
    @include media-breakpoint-down(sm) {
      margin-bottom: 15px;
      font-size: em(16);
    }
  }
}
.ui.accordion .title:not(.ui) {
  font-family: "Muli", sans-serif !important;
  padding: 0.565em 0;
}
.ui-accordion {
  .accordion {
    .title:not(.ui) {
      padding: 2em 70px 2em 40px;
      font-size: em(16);
      font-weight: $font-weight-bold;
      color: $dark;
      background-color: $white;
      margin-bottom: 20px;
      @include border-radius(4px);
      background-image: url(../images/svg/add.svg);
      background-position: right 40px center;
      background-size: 15px;
      background-repeat: no-repeat;
      position: relative;
      &.active {
        @include border-radius-separate(4px, 4px, 0, 0);
        margin-bottom: 0;
        background-image: url(../images/svg/minus.svg);
        &:after {
          content: "";
          height: 1px;
          background-color: #eaeaea;
          position: absolute;
          left: 40px;
          right: 40px;
          bottom: 0;
          @include media-breakpoint-down(sm) {
            left: 16px;
            right: 16px;
          }
        }
      }
    }
    .content {
      margin-bottom: 20px;
      padding: 2em 40px !important;
      color: $dark;
      background-color: $white;
      @include border-radius-separate(0, 0, 4px, 4px);
      &.active {
        display: block;
        animation: showNav 250ms ease-in-out both;
      }
      .accordion-title {
        font-size: em(16);
        margin-bottom: 1rem;
      }
      .pricing-infolist {
        list-style-type: none;
        padding: 0 !important;
        @extend .pl-4;
        li {
          padding-left: 20px;
          padding-bottom: 0.75rem;
          /*font-size: em(14);*/
          /*line-height: 18px;*/
          position: relative;
          &::before {
            content: "~";
            display: block;
            position: absolute;
            max-width: 0px;
            max-height: 0px;
            left: 0;
            top: 0.05em;
            color: $dark;
            font-weight: $font-weight-bold;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      .title:not(.ui) {
        padding: 1em 3em 1em 1em;
        background-position: right 1em center;
      }
      .content {
        padding: 2em 1em !important;
      }
    }
  }
}

@-webkit-keyframes showNav {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes showNav {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.process-card {
  .process.item {
    text-align: center;
  }
  .ui.attached.segment {
    border: none;
    text-align: center;
    font-size: em(20);
    @include media-breakpoint-down(sm) {
      font-size: em(16);
    }
  }
  .ui.attached.menu:not(.tabular) {
    border: none;
  }
  .ui.menu {
    margin-top: 1.5rem;
    justify-content: center;
    .item {
      height: 44px;
      width: 44px;
      margin-left: 10px;
      line-height: 44px;
      padding: 0;
      @include border-radius(50%);
      border: 1px solid #e3e4e6;
      text-align: center;
      display: inline-block;
      &:before {
        content: none;
      }
      &.active {
        background-color: #f7b7ae;
        color: $white;
        border-color: #f7b7ae;
      }
      &:first-child {
        @include border-radius(50% !important);
      }
    }
  }
}

.nannyskill-list {
  .skill-box {
    margin-top: 15px;
    .card {
      position: relative;
      cursor: pointer;
      .card-body {
        @include default_transition(0.3s, ease-in-out);
        padding: 3.5rem;
        text-align: center;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: rgba(#313131, 0.7);
        @extend .flexbox-align-c;
        @extend .flex-wrap;
        @extend .flex-column;
        & > img {
          max-height: 45px;
          @extend .w-100;
          &:hover {
            -webkit-filter: grayscale(1) invert(1);
            filter: grayscale(1) invert(1);
          }
        }
        .btn.btn-clear {
          position: absolute;
          left: 0;
          right: 0;
          margin: 0 auto;
          bottom: 50px;
          width: 100%;
          display: none;
          @include default_transition(0.3s, ease-in-out);
        }
      }
      .card-title {
        margin-top: 20px;
        font-size: em(34);
        color: $white;
        @extend .font-light;
        @extend .text-uppercase;
        @extend .w-100;
        font-weight: $font-weight-light;
      }
      .card-description {
        display: none;
      }
      &:hover {
        .card-body {
          background-color: rgba(#f6d9d5, 0.94);
          & > img.img-fluid {
            -webkit-filter: grayscale(1) invert(1);
            filter: grayscale(1) invert(1);
            opacity: 0.8;
          }
          .card-title {
            color: $heading-color;
          }
          .btn.btn-clear {
            display: block;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    .skill-box {
      .card {
        .card-img-top {
          max-height: 350px;
          object-fit: cover;
        }
        .card-body {
          .card-title {
            font-size: em(18);
          }
          .btn.btn-clear {
            bottom: 25px;
          }
        }
      }
    }
  }
  &.specialised-list {
    .skill-box {
      .card {
        .card-body {
          // padding: 3.5rem 1rem;
          .card-title {
            @include media-breakpoint-up(xll) {
              font-size: em(24);
            }
            word-break: break-word;
          }
        }
      }
      &:hover {
        .card-body {
          & > img.img-fluid {
            display: none;
          }
          .card-title {
            display: none;
          }
          .card-description {
            display: block;
          }
        }
      }
    }
  }
}

.section-skills {
  padding-top: 15px !important;
  .btn-back-skills {
    margin-bottom: 70px;
    @media (max-width: 991px) {
      margin-bottom: 15px;
    }
    @media (max-width: 767px) {
      margin-bottom: 15px;
    }
    path {
      @include default_transition(0.3s, ease-in-out);
      fill: $pink;
    }
    &:hover svg path {
      fill: darken($pink, 10%);
    }
  }
  .skill-image {
    margin-bottom: 1rem;
    @extend .float-left;
    @include media-breakpoint-up(xl) {
      margin-right: 148px;
    }
    @include media-breakpoint-down(xl) {
      margin-right: 93px;
    }
    @include media-breakpoint-down(lg) {
      margin-right: 93px;
    }
    @include media-breakpoint-down(md) {
      margin-right: 0;
      margin-bottom: 2rem;
    }
  }
  .skill-content {
    // margin-top: 120px;
    // .heading {
    //   margin: 0 0 30px 0;
    // }
    .title-heading {
      font-size: em(24);
      @extend .font-baskervilleregular;
      font-weight: $font-weight-normal;
    }
    p {
      margin-bottom: 20px;
      font-size: em(16);
    }
    .list-icon {
      .list-group-item {
        margin-bottom: 20px;
      }
    }
    .heading:not(.tt-none) {
      text-transform: lowercase;
      &::first-line {
        text-transform: capitalize;
      }
    }
    @include media-breakpoint-down(lg) {
      padding-top: 1.75rem !important;
    }
  }
  @media (max-width: 1441px) {
    .skill-image {
      max-width: 550px;
    }
  }
  @media (max-width: 1080px) {
    .skill-image {
      max-width: 450px;
    }
  }
  @media (max-width: 768px) {
    .skill-image {
      max-width: 100%;
    }
  }
  @include media-breakpoint-down(xl) {
    .heading {
      font-size: em(40);
    }
  }
  @include media-breakpoint-down(md) {
    &.section {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .heading {
      font-size: em(24);
    }
  }
}

@include media-breakpoint-down(md) {
  .border-md-0 {
    border: none !important;
  }
}

@include media-breakpoint-down(sm) {
  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }
}

.section-why {
  h4 {
    font-size: em(26);
    color: $black;
    margin-bottom: 10px;
  }
}

.why-elite-item {
  @include media-breakpoint-up(xll) {
    padding-right: 30px;
    padding-left: 30px;
  }
  @include media-breakpoint-down(xl) {
    padding-right: 15px;
    padding-left: 15px;
  }
  h6 {
    font-size: em(16);
    color: $black;
  }
  p {
    font-size: em(14);
    line-height: 18px;
  }
}

.membership-card {
  margin: 25px 70px;
  @include media-breakpoint-down(xl) {
    margin: 25px 20px;
  }
  @include media-breakpoint-down(sm) {
    margin: 25px 0;
  }
  .membership-item {
    height: 100%;
    background-color: $white;
    padding: 51px 30px 31px;
    text-align: center;
    @include box-shadow(0, 11px, 40px, 0px, rgba($black, 0.04));
    .membership-heading {
      line-height: normal;
      @extend .font-baskervilleregular;
      font-weight: normal;
      font-size: em(24);
      margin-bottom: 40px;
    }
    .membership-price {
      font-weight: $font-weight-regular;
      line-height: 30px;
      color: $black;
      font-size: 26px;
      span {
        font-size: 20px;
        display: block;
        color: $heading-color;
      }
    }
    .membership-divider {
      width: 50px;
      height: 1px;
      margin: 23px auto;
      background-color: #edbab7;
    }
    .membership-content {
      font-size: em(14);
      line-height: 18px;
      span {
        font-weight: $font-weight-bold;
        background-color: rgba(#edbab7, 0.4);
        padding: 1px 5px;
        @include border-radius(4px);
      }
      &.l-h-24 {
        line-height: 24px;
      }
    }
  }
}

.select-membership {
  .membership-chk {
    display: none;
    &:checked + .select-membership-item {
      border-color: #f1d1cc;
      @include border-radius(0);
      @include transition(all 0.2s ease-in-out);
      .membership-item {
        background-position: top 9px right 9px;
        background-repeat: no-repeat;
        background-image: url(../images/svg/tick.svg);
        background-size: 18px;
      }
    }
  }
  .select-membership-item {
    height: 100%;
    display: block;
    background-image: none;
    background-size: 18px;
    background-position: top 9px right 9px;
    background-repeat: no-repeat;
    cursor: pointer;
    @include transition(all 0.2s ease-in-out);
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    @include border-radius(5px);
  }
}

.react-tel-input input[type="text"],
.react-tel-input input[type="tel"] {
  padding-left: 44px !important;
}

.full-con-content {
  padding-left: 25px;
}

.section-request-sitter {
  .form-group {
    h4 {
      font-weight: $font-weight-semibold;
      color: $dark;
      font-size: em(16);
      text-transform: uppercase;
      @include media-breakpoint-down(sm) {
        font-size: em(14);
      }
    }
    .form-control {
      @extend .cus-control;
    }
    .form-label {
      text-transform: none;
      font-size: em(13);
      color: #8e9092;
      font-weight: normal;
    }
  }

  .request-sitter-form {
    .form-group {
      margin-bottom: 30px !important;
      &.mb-0 {
        margin-bottom: 0 !important;
      }
      h4 {
        text-align: center;
        margin-bottom: 15px;
      }
      .form-label {
        text-align: center;
        margin: 0 auto 15px auto;
        display: block;
        max-width: 450px;
      }
    }
    .custom-control-label {
      padding-left: 0 !important;
      &:before {
        top: 0.25rem;
        left: -1.5rem;
        display: block;
        width: 1.25rem;
        height: 1.25rem;
        border: 2px solid #e6aead;
      }
      &:after {
        background-size: 50%;
        top: 0.25rem;
        width: 1.25rem;
        height: 1.25rem;
      }
    }
    @include media-breakpoint-down(sm) {
      .custom-control-inline {
        display: block;
      }
    }
  }
}
.multidate-control {
  input {
    @extend .form-control;
    @extend .cus-control;
    &[readonly] {
      background-color: $white;
    }
  }
  .dyHccB {
    font-size: 1em;
    color: #8e9092;
  }
  .fKLcWV {
    max-width: 350px;
  }
  .jyqlN {
    padding-top: 7px;
  }
  .gKyapV {
    background-color: $primary;
  }
  .dsKVpZ {
    color: $dark;
  }
  .Zirqg {
    font-size: 1.25em;
  }
  .daDmt {
    border-radius: 4px;
    padding: 10px;
  }
  .dsKVpZ,
  .dhKTNs {
    margin: 0;
  }
  .jbCtqG,
  .gKyapV,
  .eeGpAO {
    min-width: 34px;
    text-align: center;
  }
  .sc-jTzLTM.heJwJj {
    display: none;
  }
  .heJwJj {
    width: 140px;
    padding: 10px 20px;
  }
}
.section-newsletter {
  .input-group {
    .form-control {
      padding-right: 7rem;
    }
  }
  select {
    -webkit-appearance: none;
  }
  .heading {
    text-transform: inherit;
  }
}

.payment-card {
  @extend .d-flex;
  @extend .justify-content-around;
  @extend .align-items-start;

  img {
    @extend .img-fluid;
    max-width: 60px;
    object-fit: contain;
    &:not(:last-child) {
      margin-right: 5px;
    }
    @include media-breakpoint-down(sm) {
      max-width: 48px;
    }
  }
}
.react-datepicker-popper {
  z-index: 14;
  .react-datepicker {
    @include border-radius(4px);
    @include box-shadow(0, 0, 20px, 0, rgba($black, 0.1));
    border: 1px solid rgba($black, 0.1);
    font-size: 1rem;

    &__header {
      background-color: $white;
      border-bottom: none;
      .react-datepicker__day-name {
        width: 2.5rem;
        line-height: 2.5rem;
      }
    }
    &__month {
      .react-datepicker__day {
        width: 2.5rem;
        line-height: 2.5rem;
      }
    }
    &__triangle {
      &:before {
        border-top-color: rgba($black, 0.1) !important;
      }
    }
  }
}

.pricingpackages {
  .select-membership-item {
    cursor: unset;
    border: none;
  }
  .membership-item {
    position: relative;
    padding-bottom: 120px;
  }
  .membership-heading {
    color: $primary;
  }
  .list-investment {
    @extend .list-unstyled;
    li {
      position: relative;
      padding-left: 16px;
      font-size: em(14);
      &:before {
        content: "";
        width: 6px;
        height: 6px;
        @include border-radius(50%);
        display: block;
        position: absolute;
        left: 0;
        top: 7px;
        background-color: $primary;
      }
    }
  }
  .packages-footer {
    position: absolute;
    bottom: 0;
    text-align: center;
    width: 100%;
    left: 0;
    padding-bottom: 50px;
    padding-left: 30px;
    padding-right: 30px;
  }
  .membership-price {
    span {
      min-height: 60px;
    }
  }
  .membership-content {
    min-height: 100px;
  }
}
.s-p-packges {
  margin-bottom: 0px;
  .membership-price {
    span {
      min-height: inherit;
    }
  }
}

.safesitter-step {
  p {
    font-size: em(14.2222);
  }
  h5 {
    margin-bottom: 1rem;
    @extend .font-baskervilleregular;
  }
  .list-group-item {
    background-position: left top 0 !important;
    margin-bottom: 2.5rem;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.sub-heading-italic {
  @extend .font-baskervilleitalic;
  font-size: em(27);
  line-height: 42px;
  @include media-breakpoint-down(lg) {
    font-size: em(22);
  }
}
.incredibly-content {
  .sub-heading {
    @extend .font-light;
    font-size: em(40);
    line-height: normal;
    @include media-breakpoint-down(lg) {
      font-size: em(28);
    }
    @include media-breakpoint-down(md) {
      //font-size: em(20);
    }
  }
  .text-white {
    color: $white !important;
  }
  .list-incredibly {
    @extend .mt-5;
    @extend .d-flex;
    @extend .justify-content-between;
    .list-line {
      position: relative;
      &:after {
        content: "";
        height: 23px;
        width: 1px;
        background: rgba($black, 0.1);
        position: absolute;
        right: 0;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
      }
      &:last-child {
        &:after {
          content: none;
        }
      }
    }
    .list-inline-item {
      text-align: center;
      position: relative;
      margin-right: 0;

      img {
        height: 49px;
        // @extend .d-block;
        // @extend .mx-auto;
      }
      .incredibly-text {
        font-weight: $font-weight-bold;
        margin: 0.5rem 0 0;
        @extend .d-block;
      }
    }
  }
  @include media-breakpoint-down(md) {
    text-align: left !important;
    .btn {
      width: 100%;
    }
  }
}

.sec-cheflist {
  text-align: center;
  position: relative;
  margin-right: 0;
  font-size: em(14);
  img {
    height: 49px;
  }
  .cheflist-text {
    font-weight: $font-weight-bold;
    margin: 0.5rem 0 0.75rem;
    @extend .d-block;
    font-size: em(18.3);
  }
}

.client-avatar {
  width: 106px;
  height: 106px;
  @include border-radius(50%);
  border: 10px solid #efdbda;
}
.landing-pages {
  .section-padding {
    padding-bottom: 150px !important;
    padding-top: 150px !important;
    @include media-breakpoint-down(lg) {
      padding-bottom: 75px !important;
      padding-top: 75px !important;
    }
  }

  .card {
    cursor: default !important;
  }
}

.skill-detail-page {
  .section-padding {
    padding-bottom: 150px !important;
    padding-top: 150px !important;
    @include media-breakpoint-down(lg) {
      padding-bottom: 75px !important;
      padding-top: 75px !important;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 30px !important;
      padding-bottom: 45px !important;
    }
  }
  .section-bg {
    @include media-breakpoint-down(lg) {
      background-color: rgba($white, 0.6);
    }
  }
}

.section-teacher,
.section {
  padding: 75px 0 !important;
  @include media-breakpoint-down(lg) {
    padding: 30px 15px !important;
  }
  &.pb-0 {
    padding-bottom: 0 !important;
  }
  &.pt-0 {
    padding-top: 0 !important;
  }
  @include media-breakpoint-down(md) {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.btn-back-right {
  position: absolute;
  top: 11px;
  @include media-breakpoint-down(md) {
    position: relative;
    top: 0;
    margin-bottom: 20px;
  }
}

.section-blog {
  .b-i-mb-5 {
    margin-bottom: 30px;
  }
  .blogitem {
    height: 100%;
    margin-inline: 2.75rem;
    @extend .bg-light;
    .card {
      @extend .bg-light;
      border: 0;
    }
    .card-img-top {
      height: 250px;
      object-fit: cover;
      @include border-radius(4px);
    }
    .card-body {
      padding: 2.5rem 2.75rem;
      @include media-breakpoint-down(xs) {
        padding: 1.5rem 1.75rem;
      }
    }
    .card-text {
      p {
        color: #9d9e9f;
        font-size: 16px;
        line-height: 18px;
        @include text-ellipsis-line(57px, 3);
      }
    }
    .card-title {
      color: $dark;
      font-size: em(20);
      font-family: $font-weight-bold;
      line-height: 24px;
      @include default_transition(0.5s, ease);
      @include text-ellipsis-line(48px, 2);
      &:hover {
        color: $primary;
      }
    }
    .blog-link {
      font-family: $font-weight-bold;
      color: $primary;
      font-size: em(16);
    }
  }
}
.page-error {
  .section.section-error-banner {
    padding: 0 0 !important;
    .title-404 {
      @extend .d-flex;
      @extend .justify-content-center;
      align-items: baseline;
      font-size: em(282);
      font-weight: $font-weight-bold;
      margin-bottom: 0;
      color: #f3cfc9;
      @include media-breakpoint-down(md) {
        font-size: em(141);
      }
    }
    .heading {
      color: $primary !important;
      text-transform: none;
      @extend .text-center;
    }
    .erroricon {
      width: 285px;
      @include media-breakpoint-down(md) {
        width: 142px;
      }
    }
  }
  .lottie-player-404 {
    width: 100% !important;
  }
  .section-blog {
    padding-bottom: 45px !important;
  }
}
.section-bg {
  @include media-breakpoint-down(lg) {
    background-color: rgba($white, 0.6);
  }
}

.skills-slider {
  padding-left: 0 !important;
  padding-right: 0 !important;
  .container-fluid {
    max-width: 100%;
  }
  margin-left: -60px;
  margin-right: -60px;
  @include media-breakpoint-down(xl) {
    margin-right: -30px;
    margin-left: -30px;
  }

  @include media-breakpoint-down(xs) {
    margin-right: -1rem;
    margin-left: -1rem;
  }
  @media (min-width: 1920px) {
    //margin-right: 0px;
    //margin-left: 0px;
  }
  .nannyskill-list {
    .skill-box {
      .card {
        border: none;
      }
    }
  }
  .slick-slider {
    .slider-arrow {
      background-color: $white;
      @include border-radius(50%);
      visibility: hidden;
      height: 48px;
      width: 48px;
      opacity: 0;
      transition: opacity 0.3s, visibility 0.3s;
      display: block;
      svg {
        height: 48px;
        width: 48px;
        path {
          fill: $white;
          &:nth-child(2) {
            fill: $dark;
          }
        }
      }
    }
    &:hover {
      .slider-arrow {
        visibility: visible;
        opacity: 1;
        transition: opacity 0.5s, visibility 0.5s;
      }
    }
    @include media-breakpoint-down(sm) {
      .slider-arrow {
        height: 34px;
        width: 34px;
        svg {
          height: 34px;
          width: 34px;
        }
      }
    }
  }
}
@include media-breakpoint-down(sm) {
  .slick-prev {
    left: 20px;
  }
  [dir="rtl"] .slick-prev {
    right: 20px;
    left: auto;
  }
  .slick-next {
    right: 30px;
  }

  [dir="rtl"] .slick-next {
    right: auto;
    left: 30px;
  }
}

.box-gradient {
  background-color: rgba(250, 250, 252, 1);

  @include media-breakpoint-up(xll) {
    background-image: -moz-linear-gradient(to right, rgba(255, 255, 255, 1) 14%, rgba(250, 250, 252, 1) 14%);
    background-image: -webkit-linear-gradient(to right, rgba(255, 255, 255, 1) 14%, rgba(250, 250, 252, 1) 14%);
    background-image: -webkit-gradient(to right, rgba(255, 255, 255, 1) 14%, rgba(250, 250, 252, 1) 14%);
    background-image: -o-linear-gradient(to right, rgba(255, 255, 255, 1) 14%, rgba(250, 250, 252, 1) 14%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 14%, rgba(250, 250, 252, 1) 14%);
  }
  @include media-breakpoint-down(xl) {
    background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 1) 9%, rgba(250, 250, 252, 1) 9%);
    background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 9%, rgba(250, 250, 252, 1) 9%);
    background-image: -webkit-gradient(90deg, rgba(255, 255, 255, 1) 9%, rgba(250, 250, 252, 1) 9%);
    background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 1) 9%, rgba(250, 250, 252, 1) 9%);
    background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 9%, rgba(250, 250, 252, 1) 9%);
  }
  @media (max-width: 1280px) {
    @include media-breakpoint-down(xl) {
      background-image: -moz-linear-gradient(90deg, rgba(255, 255, 255, 1) 3%, rgba(250, 250, 252, 1) 3%);
      background-image: -webkit-linear-gradient(90deg, rgba(255, 255, 255, 1) 3%, rgba(250, 250, 252, 1) 3%);
      background-image: -webkit-gradient(90deg, rgba(255, 255, 255, 1) 3%, rgba(250, 250, 252, 1) 3%);
      background-image: -o-linear-gradient(90deg, rgba(255, 255, 255, 1) 3%, rgba(250, 250, 252, 1) 3%);
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 1) 3%, rgba(250, 250, 252, 1) 3%);
    }
    .offset-xl-3 {
      margin-left: 17%;
    }
    .pl-xl-4,
    .px-xl-4 {
      padding-left: 0 !important;
    }
  }
  @media (max-width: 1080px) {
    background-image: none !important;
    .offset-xl-3 {
      margin-left: 8.33333%;
    }
  }
  @media (max-width: 769px) {
    margin-top: 30px;
    background-color: transparent;
    .offset-xl-3 {
      margin-left: 0;
    }
    margin-left: -15px;
    margin-right: -15px;
    padding: 0 15px;
  }
  @media (min-width: 1960px) {
    background-image: -moz-linear-gradient(to right, rgba(255, 255, 255, 1) 16.25%, rgba(250, 250, 252, 1) 16.25%);
    background-image: -webkit-linear-gradient(to right, rgba(255, 255, 255, 1) 16.25%, rgba(250, 250, 252, 1) 16.25%);
    background-image: -webkit-gradient(to right, rgba(255, 255, 255, 1) 16.25%, rgba(250, 250, 252, 1) 16.25%);
    background-image: -o-linear-gradient(to right, rgba(255, 255, 255, 1) 16.25%, rgba(250, 250, 252, 1) 16.25%);
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 16.25%, rgba(250, 250, 252, 1) 16.25%);
    .pl-xll-5,
    .px-xll-5 {
      padding-left: 5rem !important;
    }
  }
}

.joblist {
  &-item {
    @extend .border-bottom;
    padding-bottom: 2rem;
    margin-bottom: 2.25rem;
    .post-date {
      color: #9d9e9f;
      font-size: em(16);
    }
    .post-title {
      font-size: em(40);
      font-family: "baskvillwebfont";
      font-weight: $font-weight-normal;
      margin-bottom: 1.25rem;
    }
    .post-list {
      @extend .list-unstyled;
      li {
        position: relative;
        &:not(:last-child) {
          padding-right: 0.75rem;
          &:after {
            content: "";
            padding-right: 1rem;
            border-right: 1px solid $gray-500;
          }
        }
      }
    }
    .post-text {
      margin-bottom: 0.5rem;
      // padding-right: 1rem;
      // max-height: 350px;
      // overflow-y: scroll;
      &::-webkit-scrollbar {
        height: 5px;
        width: 3px;
        background-color: rgba($dark, 0.1);
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba($dark, 0.5);
        -webkit-border-radius: 20px;
        height: 100px;
      }
    }
    .post-action {
      @extend .d-flex;
      @extend .justify-content-between;
      @extend .align-items-center;
      margin-top: 1rem;
    }
    .post-rate {
      @extend .mb-0;
      font-size: em(24);
      font-family: "baskvillwebfont";
      font-weight: $font-weight-normal;
      strong {
        // @extend .font-baskervillebold;
      }
    }
    @include media-breakpoint-down(lg) {
      .post-title {
        font-size: em(24);
      }
      .post-text {
        margin-bottom: 1rem !important;
      }
      .post-action {
        display: block !important;
      }
      .post-rate {
        font-size: em(18);
        margin-bottom: 1.25rem !important;
      }
    }
  }
}
.text-status-success {
  color: #0bbe4a;
}
.text-status-filled {
  color: $dark;
}

.job-banner {
  .btn-back {
    @include media-breakpoint-down(md) {
      position: relative !important;
      text-align: left;
    }
  }
}

.text-inherit {
  text-transform: inherit !important;
}