// Componant: App
.app-layout{
    width: 100%;
    min-height: 100%;
    position: relative;
    color: #575555 !important
}

.page-wrapper{
    position: relative;
    min-height: calc(100vh - #{$header-height});
    padding: $header-height 0 0;
    @include transition(margin .3s, ease-in-out);
    @include media-breakpoint-down(lg) {
        min-height: calc(100vh - 60px);
        padding: 60px 0 0;
    }
    @media (min-width: 1921px){
        .container-fluid{
            max-width: 1923px;
            margin: 0 auto;
        }
    }
}
$cus-footer-height: 392px;
.page-content{
    margin-right: 60px;
    margin-left: 60px;
    margin-top: 58px;
    min-height: calc(100vh - #{$header-height + $cus-footer-height});

    @include media-breakpoint-down(xl) {
        margin-right: 30px;
        margin-left: 30px;
    }

    @include media-breakpoint-down(xs) {
        margin-right: 1rem;
        margin-left: 1rem;
        margin-top: 1.5rem;
    }

    &.page-auth,
    &.page-details{
        .container{
            @include media-breakpoint-down(md) {
                padding-left:0;
                padding-right:0;
            }
            .section{
                @include media-breakpoint-down(md) {
                    padding-left:1.5rem !important;
                    padding-right:1.5rem !important;
                }
            }
        }
    }


    &.page-contact,
    &.page-account{
        .container{
            .section{
                @include media-breakpoint-down(md) {
                    padding:3rem;
                }
                @include media-breakpoint-down(sm) {
                    padding:1.5rem;
                }
                @include media-breakpoint-down(xs) {
                    padding:0;
                }
            }
        }
    }

    &.page-contact,
    &.page-account{
        .container{
            .section{
                &.nanny-detail {
                    padding: 70px 50px;
                    @include media-breakpoint-down(xl) {
                        padding: 50px;
                    }
                    @include media-breakpoint-down(xs) {
                        padding: 30px 20px;
                    }
                }
            }
        }
    }

    &.page-nannies{
        .container{
            @include media-breakpoint-down(md) {
                padding-left:0;
                padding-right:0;
            }

            .section{
                @include media-breakpoint-down(md) {
                    padding:0rem;
                }
                @include media-breakpoint-down(sm) {
                    padding:0rem;
                }
            }
        }
    }

    &.page-account{
        .container{
            @include media-breakpoint-down(md) {
                padding-left:0;
                padding-right:0;
            }
        }
    }
}

.parallax-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    .parallax {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: -1;
        img {
            opacity: 0;
            position: absolute;
            left: 50%;
            bottom: 0;
            min-width: 100%;
            min-height: 100%;
            -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }
}
