// Componant: Dropdown
// Updated styles for bootstrap dropdown

.dropdown-toggle{
    display: inline-block;
    position: relative;
    &:after{
        border: solid #e6aead;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 4px;
        transform: rotate(45deg);
        -webkit-transform: rotate(42deg);
        height: 0;
        right: 10px;
        position: absolute;
        top: 14px;
        width: 0;
    }
}
.dropdown-menu{
    border: none;
    @include box-shadow(0, 11px, 40px, 0, rgba($black, 0.1));
    .dropdown-item{
        font-weight: $font-weight-regular !important;
        color: $body-color;
        .user-title{
            
        }
    }
}