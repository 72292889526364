// Componant: Form
.form-control{
  @include input-placeholder {
    color: $black;
    font-size: 1rem;
    opacity: 0.3;
  }
  @include media-breakpoint-down(xs) {
    @include input-placeholder {
      font-size: 1rem;
    }
  }

  &.is-invalid{
    border-color: rgba($danger, 0.3) !important;
  }
}
.form-group{
  margin-bottom: 12px;
  .css-j7kmfc {
    font-size: 1rem;
  }
}
.form-label{
  font-size: em(12);
  color: $heading-color;
  text-transform: uppercase;
  margin-bottom: 7px;
  font-weight: $font-weight-semibold;
}
.form-control{
  height: calc(3.786rem + 4px) !important;
  &.cus-control{
    padding: 0.887rem 1.5rem;
    border-width: 2px;
    border-style: solid;
    border-color: #fff;
    @include box-shadow(16px, 19px, 41.85px, 3.15px, rgba($black, 0.04));
    &:focus{
      border-color: rgba($primary, 0.5);
    }
  }
  &.search{
    padding-right: 3rem;
    background-image: url(../images/svg/search.svg);
    background-size: 15px 15px;
    background-repeat: no-repeat;
    background-position: center right 22px;
  }
}
.form-group-btn{
  .append-left{
    .input-group-append{
      left: 0;
      right: auto;
    }
    .form-control{
      padding-left: 55px;
    }
  }
  .input-group-append{
    position: absolute;
    right: 0;
    z-index: 9;
    .btn{
      min-height: 57px;
      text-transform: uppercase;
      font-weight: $font-weight-semibold;
    }
    &.append-left{
      left: 0;
      right: auto;
    }
  }
}
.input-group {
  .form-control{
    padding-right: 3.5rem;
  }
  > .form-control,
  > .custom-select {
    &:not(:last-child) { @include border-right-radius($border-radius); }
    &:not(:first-child) { @include border-left-radius($border-radius); }
  }
}
.auto-select{
  &>div{
    border: none;
    @extend .form-control;
    @extend .d-flex;
    @extend .px-3;
    height: auto !important;
  }
  .css-d8oujb{
    width: 0;
    background-color: transparent;
  }
  .css-xwjg1b{
    @include border-radius(2px);
    border-color: rgba($primary, 0.5);
    border-width: 2px;
    border-style: solid;
    padding: 7px 9px;
    background-color: #fdf9f9;
    font-weight: $font-weight-normal;
  }
  .css-1492t68{
    color: $black;
    font-size: 16px;
    opacity: 0.3;
  }
}
.cus-timepicker{
  .rc-time-picker-input{
    border: none;
    font-size: em(16);
    .rc-time-picker-clear{
      top: 15px;
      &:focus{
        outline: 0;
      }
    }
    &:focus{
      outline: 0;
    }
  }
}
.rc-time-picker-panel-select{
  &::-webkit-scrollbar {
    height: 5px;
    width: 3px;
    background-color: $body-bg;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darken($body-bg,20%);
    -webkit-border-radius: 0px;
    height: 100px;
  }

  &::-webkit-scrollbar-corner {
    background: $body-color;
  }
}

.request-sitter-form{
  .form-group{
    h4{
      font-weight: 600;
      color: #555657;
      font-size: 1em;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 15px;
    }
    .form-label{
      text-transform: none;
      font-size: 0.8125em;
      color: #8e9092;
      font-weight: normal;
    }
  }
}

.css-d8oujb {
  display: none;
}
.cus-control-sm{
.custom-control-label{
  margin-bottom: 0 !important;
  padding-left: 5px !important;
  line-height: 23px !important;
  &:before , &:after{
    width: 21px;
    height: 21px;
  }
  }
  .custom-checkbox {
    .custom-control-input {
      &:checked ~{
          .custom-control-label {
            &::after {
              background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
            }
          }

      }
    }
  }

}

.icon-down{
  padding-right: 3rem;
  background-image: url(../images/arrow-down.svg);
  background-size: 15px 15px;
  background-repeat: no-repeat;
  background-position: center right 1rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
