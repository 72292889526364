// Componant: Page Home
.page-nannies {
  margin-top: 63px;
  @include media-breakpoint-down(sm) {
    margin-top: 1.5rem;
  }

  .section {
    padding: 0 0 150px;
  }

  .nannies-contact {
    // margin-left: 80px;
    // @include media-breakpoint-down(xl) {
    //   margin-left: 3rem;
    // }
    // @include media-breakpoint-down(lg) {
    //   margin-left: -15px;
    //   margin-right: -15px;
    // }

    .heading {
      font-size: em(24);
    }
  }

  .welcome-content {
    padding: 65px;
    @include media-breakpoint-down(sm) {
      padding: 30px;
    }

    .heading {
      margin-bottom: 15px;
    }
  }

  .nannies-list {
    .nannies-list-item {
      padding: 60px 30px;
      .media {
        &-body {
          .list-inline {
            li{
              font-size: em(14);
            }
          }
        }
      }
      @include media-breakpoint-down(lg) {
        padding: 3rem 1rem;
      }
      @include media-breakpoint-down(xs) {
        .media {
          &-body {
            width: 100%;
            flex: auto;

            h5,
            .nannies-details {
              margin: .75rem 0;
            }

            .list-inline {
              li {
                width: 100%;
                margin: 0 0 5px 0;
              }
            }
          }
        }
      }
      position: relative;
      border-bottom: 1px solid #d9e1e5;
      transition: all .5s ease;
      -webkit-transition: all .5s ease;
      -moz-transition: all .5s ease;
      &:after {
        content: "";
        background: #fff;
        opacity: 0;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 1px;
        -webkit-transition: all .5s ease;
        -moz-transition: all .5s ease;
      }
      &:hover {
        -webkit-box-shadow: 0 11px 40px 0 rgba(0, 0, 0, 0.04);
        -moz-box-shadow: 0 11px 40px 0 rgba(0, 0, 0, 0.04);
        box-shadow: 0 11px 40px 0 rgba(0, 0, 0, 0.04);
        border-bottom: 1px solid rgba(0, 0, 0, 0);
        &:after {
          opacity: 1;
        }
      }
      .item-nannies-avatar {
        width: 124px;
        height: 124px;
        @include border-radius(50%);
        margin-right: 52px;
        object-fit: cover;
        @include media-breakpoint-down(lg) {
          margin-right: 1.5rem;
        }
        @include media-breakpoint-down(xs) {
          width: 200px;
          height: 200px;
          margin: 0 auto;
          display: block;
        }
        margin-top: -12px;
        @extend .box-shadow;
        border: 12px solid $white;
      }

      .item-nannies-name {
        font-size: em(16);
        font-family: $font-family-heading;
        color: $heading-color;
        font-weight: $font-weight-regular;
        letter-spacing: 1px;
        text-transform: uppercase;
        margin-top: 0;
        margin-bottom: 0;
      }

      .nannies-contact-info {
        .contact-info-item {
          font-size: em(14);
          margin-bottom: 0;
          line-height: 18px;
        }
      }

      .nannies-skill-list {
        font-size: 0.875em;
      }
    }
    & > a {
      &:last-child {
        .nannies-list-item{
          border-bottom: none;
        }
      }
    }
  }
}
