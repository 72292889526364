/* CircularStd @font-face kit */
@import url("https://fonts.googleapis.com/css?family=Muli:300,400,600,700");

@font-face {
  font-family: "libre_baskervillebold";
  src: url("../fonts/baskerville/librebaskerville-bold-webfont.eot");
  src: url("../fonts/baskerville/librebaskerville-bold-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/baskerville/librebaskerville-bold-webfont.woff2") format("woff2"),
    url("../fonts/baskerville/librebaskerville-bold-webfont.woff") format("woff"),
    url("../fonts/baskerville/librebaskerville-bold-webfont.ttf") format("truetype"),
    url("../fonts/baskerville/librebaskerville-bold-webfont.svg#libre_baskervillebold") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "libre_baskervilleitalic";
  src: url("../fonts/baskerville/librebaskerville-italic-webfont.eot");
  src: url("../fonts/baskerville/librebaskerville-italic-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/baskerville/librebaskerville-italic-webfont.woff2") format("woff2"),
    url("../fonts/baskerville/librebaskerville-italic-webfont.woff") format("woff"),
    url("../fonts/baskerville/librebaskerville-italic-webfont.ttf") format("truetype"),
    url("../fonts/baskerville/librebaskerville-italic-webfont.svg#libre_baskervilleitalic") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Baskerville";
  src: url("../fonts/baskerville/librebaskerville-regular-webfont.eot");
  src: url("../fonts/baskerville/librebaskerville-regular-webfont.eot?#iefix") format("embedded-opentype"),
    url("../fonts/baskerville/librebaskerville-regular-webfont.woff2") format("woff2"),
    url("../fonts/baskerville/librebaskerville-regular-webfont.woff") format("woff"),
    url("../fonts/baskerville/librebaskerville-regular-webfont.ttf") format("truetype"),
    url("../fonts/baskerville/librebaskerville-regular-webfont.svg#libre_baskervilleregular") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Baskervillelight";
  src: url("../fonts/baskerville/BaskOldFace.eot");
  src: url("../fonts/baskerville/BaskOldFace.eot?#iefix") format("embedded-opentype"),
    url("../fonts/baskerville/BaskOldFace.woff2") format("woff2"),
    url("../fonts/baskerville/BaskOldFace.woff") format("woff"),
    url("../fonts/baskerville/BaskOldFace.ttf") format("truetype"),
    url("../fonts/baskerville/BaskOldFace.svg#BaskOldFace") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "baskvillwebfont";
  src: url("../fonts/baskerville/baskvillwebfont.eot");
  src: url("../fonts/baskerville/baskvillwebfont.eot") format("embedded-opentype"),
    url("../fonts/baskerville/baskvillwebfont.woff2") format("woff2"),
    url("../fonts/baskerville/baskvillwebfont.woff") format("woff"),
    url("../fonts/baskerville/baskvillwebfont.ttf") format("truetype"),
    url("../fonts/baskerville/baskvillwebfont.svg#baskvillwebfont") format("svg");
}

.font-muli {
  font-family: "Muli", sans-serif !important;
}
.font-baskervillebold {
  font-family: "libre_baskervillebold" !important;
}
.font-light {
  font-family: "Baskervillelight" !important;
}
.font-baskervilleitalic {
  font-family: "libre_baskervilleitalic" !important;
}
.font-baskervilleregular {
  font-weight: $font-weight-regular;
  font-family: "Baskerville" !important;
}
