//
// Application Variables
//-----------------------------------------------------

// ------- Header --------
$header-height: 85px;

// ------- Sidebar --------
$sidebar-width: 0;
$sidebar-color: #FFFFFF;

$border-radius : 4px;
$headings-font-weight: bold;


// sidebar right

$sidebar-right-width: 460px;
$sidebar-right-color: #FFFFFF;