// Componant: Modal
body{
  &.modal-open{
    max-height: 100vh;
    .app-layout{
      // max-height: 100vh;
      // overflow: hidden;
    }
  }
}
