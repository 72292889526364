// Componant: Header

.header{
  @extend %box-shadow;
  padding-left: 60px;
  padding-right: 60px;
  background-color: $white;
  .nav{
    height: $header-height;
  }
  .navbar-logo{
    .navbar-brand{
      cursor: pointer;
      margin-right: 8rem;
      height: 100%;
      @include link($primary, $primary, $primary, $primary);
      @extend %flexbox-c;
      .brand-logo{
        background-image: url(../images/logo.svg);
        background-size: 150px 46px;
        width: 150px;
        height: 46px;
      }
    }
  }

  .list-inline-item{
    line-height: normal;
    padding: 0 0.8rem;
    position: static;
    .btn{
      padding: .75rem 2.485rem;
      font-weight: $font-weight-bold;
      font-size: em(14) !important;
    }
    .btn-sm{
      padding: .75rem 1.25rem;
      font-size: em(12) !important;
    }
    .sub-menu{
      @extend %box-shadow;
      background-color: #ffffff;
      padding: 45px 0;
      visibility: hidden;
      opacity: 0;
      position: fixed;
      top: 65px;
      left: 0;
      width: 100%;
      transform: translateY(-2em);
      z-index: -1;
      transition: all 0.3s ease-in-out 0s, visibility 0s linear 0.3s, z-index 0s linear 0.01s;
      .sub-menu-container{
        max-width: 750px;
        @media (min-width: 1560px){
          margin-left: 322px;
        }
      }
      .submenu-heading{
        font-family: $font-family-base;
        font-size: em(16);
        color: $dark;
        margin-bottom: 0;
        // @extend .font-baskervilleregular;
      }
      .submenu-list{
        padding-top: 20px;
        .submenu-item{
          @include border-radius(4px);
          .btn{
            // font-size: em(14);
            font-weight: $font-weight-normal;
            color: $dark;
            padding-left: 15px;
          }
          .btn-outline-primary{
            font-weight: $font-weight-bold;
            font-size: em(13) !important;
            &:hover{
              color: $white;
            }
          }
          img{
            @extend .pr-3;
            height: 28px;
          }
          &:hover{
            @extend .bg-light;
          }
        }
      }
    }
    &:hover{
      .sub-menu{
        visibility: visible;
        opacity: 1;
        z-index: 1;
        transform: translateY(0%);
        transition-delay: 0s, 0s, 0.3s;
      }
    }
    &:first-child{
      padding-left: 0;
      .nav-link{
        padding-left: 0;
      }
    }
    &:last-child{
      padding-right: 0;
    }
    &.sub-menu-item{
      background-image: url(../images/svg/nav-down-arrow.svg);
      background-position: right center;
      background-size: 14px 8px;
      background-repeat: no-repeat;
    }
    .dropdown {
    }
    &:not(:last-child){
      margin-right: 0;
    }
  }
  .nav-link{
    font-size: em(14);
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    padding: .75rem .5rem;
    color: $dark;
    @include default_transition(0.3s, ease-in-out);
    &:hover, &:focus{
      text-decoration: none;
      outline: none;
      color: $primary;
    }
    &.active{
      color: $primary;
    }
  }
  .ml-auto{
    .list-inline-item{
      &:first-child{
        padding-left: 0;
        .nav-link{
          padding-left: 0;
        }
      }
    }
  }

  @media (max-width: 1280px){
    .navbar-logo{
      .navbar-brand{
        margin-right: 2rem;
      }
    }
    .nav-link{
      font-size: em(13);
    }
  }
  @include media-breakpoint-down(xll) {
    .list-inline-item{
      margin-right: 0;
    }
  }
  @media (max-width: 1256px){
    nav{
      &.nav{
        justify-content: space-between !important;
      }
      ul
      {
        &.nav{
          display: none !important;
        }
      }
    }
  }
  @include media-breakpoint-down(lg) {
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    .nav{
      height: 60px;
      .navbar-logo{
        .navbar-brand{
          .brand-logo{
            background-size: 140px 43px;
            width: 140px;
            height: 43px;
          }
        }
      }
    }
  }
  @include media-breakpoint-down(xl) {
    padding-left: 30px;
    padding-right: 30px;
  }
  @media (max-width: 1560px){
    .navbar-logo{
      .navbar-brand{
        margin-right: 4rem;
      }
    }
    .nav-link{
      font-size: em(14);
      padding: .75rem .25rem;
    }
    .sub-menu-container{
      max-width: 750px;
      margin-left: 237px;
    }
  }
  @media (max-width: 1390px){
    .navbar-logo{
      .navbar-brand{
        margin-right: 1.75rem;
        .brand-logo{
          background-size: 140px 43px;
          width: 140px;
          height: 43px;
        }
      }
    }
    .nav-link{
      font-size: em(13);
      padding: .75rem 0rem;
    }
    .sub-menu-container{
      max-width: 750px;
      margin-left: 196px;
    }
    .list-inline-item{
      &.sub-menu-item{
        background-size: 12px 7px;
        background-position: right -2px center;
      }
    }
  }
}

.hamburger {
  padding: 15px;
  display: none;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
  &:hover {
    opacity: 0.7;
  }
  @media (max-width: 1256px){
    display: block;
  }
}

.hamburger-box {
  width: 26px;
  height: 24px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: 2px;
  &,
  &::before,
  &::after {
    width: 26px;
    height: 2px;
    background-color: rgba(52, 58, 64, 0.3);
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
  }
  &::before,
  &::after {
    content: "";
    display: block;
  }
  &::before {
    top: -8px;
  }
  &::after {
    bottom: -8px;
  }
}

.hamburger--squeeze {
  .hamburger-inner {
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &::before {
      transition: top 0.075s 0.12s ease,
        opacity 0.075s ease;
    }

    &::after {
      transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
  }

  &.is-active {
    .hamburger-inner {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &::before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease,
          opacity 0.075s 0.12s ease;
      }

      &::after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease,
          transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }
  }
}

.sidebar-nav{
  position:fixed;
  top:60px;
  right:-390px;
  z-index:1030;
  width:390px;
  height:calc(100% - 60px);
  overflow: scroll;
  background:#fff;
  webkit-box-shadow: 0 11px 40px 0 rgba(0, 0, 0, 0.04);
  -moz-box-shadow: 0 11px 40px 0 rgba(0, 0, 0, 0.04);
  box-shadow: 0 11px 40px 0 rgba(0, 0, 0, 0.04);
  transition:all .5s ease;
  -webkit-transition:all .5s ease;
  -moz-transition:all .5s ease;
  padding: 1rem 2rem;

  @include media-breakpoint-down(xs) {
    width: 100%;
    right: -100%;
    .btn {
      display: block;
      margin: 10px 0 !important;
      &.ml-2{
        margin-left: 0.5rem !important;
      }
      &.ml-2{
        margin-right: 0.5rem !important;
      }
    }
  }
  &::-webkit-scrollbar {
    height: 0;
    width: 5px;
    background-color: $primary;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darken($primary,10%);
    -webkit-border-radius: 20px;
    height: 100px;
  }

  &::-webkit-scrollbar-corner {
    background: #000;
  }
  &.visible{
    right:0px;
  }
  p{
    margin-bottom: 1.1rem;
    &:last-child{
      margin-bottom: 1.1rem;
    }
  }
  a{
    color: $body-color;
    text-transform: none;
  }

  .pink-link {
    color: #e6aead;
  }


  .accordion{
    .title{
      padding: 2em 5px;
      font-size: em(16);
      font-weight: $font-weight-bold;
      color: $dark;
      background-color: $white;
      margin-bottom: 0;
      @include border-radius(4px);
      background-image: url(../images/svg/down-arrow.svg);
      background-position: right 5px center;
      background-size: 15px;
      background-repeat: no-repeat;
      position: relative;
      &.active {
        @include border-radius-separate(4px, 4px, 0, 0);
        margin-bottom: 0;
      }
    }
    .content{
      p{
        margin-bottom: 1.25rem;
        padding-left: 1.25rem;
        position: relative;
        color: $body-color;
        &:last-child{
          margin-bottom: 1.25rem;
        }
        &.before-none{
          &:before{
            content: none !important;
          }
        }
        &:before{
          content: '';
          width: 10px;
          height: 1px;
          background-color: $gray-400;
          display: block;
          position: absolute;
          left: 0;
          top: 50%;
        }
        a{
          color: $body-color;
          text-transform: none;

        }

      }
      a{
        color: $body-color;
        text-transform: none;
      }
    }
  }
}
.btn-dark.text-white, .sidebar-nav .accordion .content a.btn-dark.text-white{
  color: $white !important;
}
