/*pixel to em convert*/
/*font-size: em(15);*/
$browser-context: 16;
@function em($pixels, $context: $browser-context) {
    @return #{$pixels/$context}em
}

// black
/*box-shadow: 0 0 4px black(0.3);*/
@function black($opacity) {
    @return rgba(0,0,0,$opacity);
}

// white
/*box-shadow: 0 0 4px white(0.3);*/
@function white($opacity) {
    @return rgba(255,255,255,$opacity);
}