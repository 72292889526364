// Componant: Sidebar
.sidebar{
    padding: 25px 40px 50px;
    @include media-breakpoint-down(sm) {
        padding:1.5rem;
    }

    .heading{
        font-size: em(24) !important;
        padding: 20px 0;
        margin-bottom: 0;
    }
    .form-label{
        color: #9d9e9f;
    }
    .form-control{
        border-color: transparent;
    }
    .btn-lg{
        padding: .719rem 2.33rem;
    }.sidebar-nav
    .btn-filter{
        position: relative;
        @extend .btn-clear;
        @extend .text-primary;
        @extend .py-0;
        @extend .pr-4;
        b{
            border: solid $primary;
            border-width: 0 2px 2px 0;
            display: inline-block;
            padding: 4px;
            transform: rotate(225deg);
            -webkit-transform: rotate(225deg);
            top: 6px;
            height: 0;
            right: 0;
            position: absolute;
            width: 0;
        }
        &.filter-hide{
            b {
                top: 1px;
                -webkit-transform: rotate(42deg) !important;
            }
        }
    }
    .select2{
        .select2-selection--single{
            height: 51px;
            .select2-selection__arrow{
                height: 51px;
                b{
                    margin-top: -8px;
                    border-color: rgba($black, 0.3);
                }
            }
            .select2-selection__rendered{
                line-height: 51px;
            }
        }
    }
}
