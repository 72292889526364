/*!
 * Copyright 2018 Authors Elite Nanny
 */

// Customize Bootstrap
@import 'custombootstrap/customize-bootstrap';

// Bootstrap
@import 'bootstrap/bootstrap';

// Partial --  Custom mixins and function
@import 'partial/mixins';
@import 'partial/function';

// Basics Files
@import 'custombootstrap/vars';
@import 'custombootstrap/reset';
@import 'custombootstrap/fonts';
@import 'custombootstrap/app';
@import 'custombootstrap/flexbox';
@import 'custombootstrap/utility';
@import 'custombootstrap/ovarlay';

// Component
@import "custombootstrap/footer";
@import "custombootstrap/select2";
@import 'custombootstrap/header';
@import 'custombootstrap/sidebar';
@import 'custombootstrap/dropdown';
@import 'custombootstrap/forms';
@import 'custombootstrap/list';
@import 'custombootstrap/card';
@import 'custombootstrap/modal';
@import 'custombootstrap/badge';
@import 'custombootstrap/button';
@import 'custombootstrap/toggle-button';
@import 'custombootstrap/widgets';
@import "custombootstrap/datatables";
@import 'custombootstrap/app-title';


// Pages
@import "pages/page-home";
@import "pages/component";
@import "pages/authentication-page";
@import "pages/page-contact";
@import "pages/page-our-difference";
@import "pages/page-details";
@import "pages/page-nannies";