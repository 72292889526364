// utility Classes
.text-black {
  color: $black;
}

// font size
.font-11 {
  font-size: em(11) !important;
}
.font-12 {
  font-size: em(11) !important;
}
.font-13 {
  font-size: em(13) !important;
}
.font-14 {
  font-size: em(14) !important;
}
.font-15 {
  font-size: em(15) !important;
}
.font-16 {
  font-size: em(16) !important;
}
.font-17 {
  font-size: em(17) !important;
}
.font-18 {
  font-size: em(18) !important;
}
.font-20 {
  font-size: em(19) !important;
}
.font-24 {
  font-size: em(20) !important;
}
.font-26 {
  font-size: em(26) !important;
}
.font-30 {
  font-size: em(30) !important;
}
.font-36 {
  font-size: em(36) !important;
}
.font-40 {
  font-size: em(40) !important;
}
.font-60 {
  font-size: em(60) !important;
}

.text-14 {
  font-size: 14px;
}
.font-small {
  font-size: 13.33px;
}

// icon size
.ic-15x {
  font-size: 1.2em !important;
}
.ic-150x {
  font-size: 1.5em !important;
}
.ic-175x {
  font-size: 1.75em !important;
}
.ic-2x {
  font-size: 2em !important;
}
.ic-3x {
  font-size: 3em !important;
}
.ic-4x {
  font-size: 4em !important;
}
.ic-5x {
  font-size: 5em !important;
}
.ic-6x {
  font-size: 6em !important;
}
.ic-50 {
  font-size: 3.6em !important;
}

// font weight
.font-light {
  font-weight: 300 !important;
}
.font-normal {
  font-weight: 400 !important;
}
.font-strong {
  font-weight: 500 !important;
}
.font-bold {
  font-weight: 700 !important;
}
.font-black {
  font-weight: 900 !important;
}

// images
.img-middle {
  margin: 0 auto;
  display: block;
}

/* Opacity helpers */
.opacity-0,
.hov-opacity-0:hover {
  opacity: 0 !important;
}
.opacity-1,
.hov-opacity-1:hover {
  opacity: 0.1 !important;
}
.opacity-2,
.hov-opacity-2:hover {
  opacity: 0.2 !important;
}
.opacity-3,
.hov-opacity-3:hover {
  opacity: 0.3 !important;
}
.opacity-4,
.hov-opacity-4:hover {
  opacity: 0.4 !important;
}
.opacity-5,
.hov-opacity-5:hover {
  opacity: 0.5 !important;
}
.opacity-6,
.hov-opacity-6:hover {
  opacity: 0.6 !important;
}
.opacity-7,
.hov-opacity-7:hover {
  opacity: 0.7 !important;
}
.opacity-8,
.hov-opacity-8:hover {
  opacity: 0.8 !important;
}
.opacity-9,
.hov-opacity-9:hover {
  opacity: 0.9 !important;
}
.opacity-10,
.hov-opacity-10:hover {
  opacity: 1 !important;
}

// Width
.width-40 {
  width: 40px;
}
.vh-100 {
  min-height: 100vh;
}
// Cursor Utility
.c-pointer {
  cursor: pointer;
}

// line height
.line-h-1 {
  line-height: 1;
}
.line-h-1-25 {
  line-height: 1.25;
}
.line-h-1-43 {
  line-height: 1.43;
}
.line-h-2 {
  line-height: 2;
}

// flexbox
%flexbox {
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: -webkit-box !important;
  display: flex !important;
  -webkit-box-pack: justify !important;
  -webkit-justify-content: space-between !important;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.flexbox {
  @extend %flexbox;
}
%flexbox-c {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.flexbox-c {
  @extend %flexbox-c;
}

%flexbox-align-c {
  display: -webkit-box !important;
  display: -webkit-flex !important;
  display: -ms-flexbox !important;
  display: flex !important;

  justify-content: center !important;
  -ms-flex-pack: justify !important;
  -webkit-justify-content: center;

  -webkit-box-align: center !important;
  -webkit-align-items: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
}
.flexbox-align-c {
  @extend %flexbox-align-c;
}
.min-w-auto {
  min-width: auto !important;
}

.max-h-40 {
  max-height: 40px !important;
}

.justify-content-evenly {
  -webkit-justify-content: space-evenly;
  justify-content: space-evenly;
}
.tt-none{
  text-transform: unset !important;
}
