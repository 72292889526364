// Componant: Page Home
.page-details{
  margin-bottom: 60px;
    section.section{
        .heading {
            font-size: em(24);
            &.sub-heading{
                font-size: em(16);
            }
        }
        @include media-breakpoint-down(sm) {
            padding:1.25rem 0;
            margin-bottom: 1.25rem;
        }
    }
    .heading{
        font-size: em(24);
        margin-bottom: 15px;
    }
    .nannies-details{
        ul{
            margin-bottom: 50px;
            li{
                font-size: em(14);
                color: $black;
                line-height: 18px;
                white-space: nowrap;
            }
        }
        .btn-edit{
            width: 48px;
            height: 48px;
            text-align: center;
            padding: 0;
            line-height: 40px;
            background-color: $primary;
            @extend .rounded-circle;
            &:hover{
                background-color: darken($primary, 5%);
            }
        }
        @include media-breakpoint-down(sm) {
            .heading{
                // text-align: center;
                margin-top: 
                    1.5rem;
            }
        }
    }
    .nannies-profile-avatar{
        .nannies-profile-image{
            border: 25px solid $white;
            @include border-radius(50%);
            object-fit: cover;
            margin-bottom: 25px;
            width: 244px;
            height: 244px;
            cursor: pointer;
            @extend %box-shadow;
            @include transition(all 0.4s ease-in-out);
            margin: 0 auto;
            display: block;
            @include media-breakpoint-down(sm) {
                border-width: 15px;
                width: 200px;
                height: 200px;
            }
        }
        .cam-icon {
            position: absolute;
            z-index: 1;
            background:#e6aead url(../images/photo-camera.svg) center center no-repeat;
            bottom: -24px;
            left: 50%;
            width: 48px;
            height: 48px;
            pointer-events: none;
            @include border-radius(100%);
            -moz-transform: translateX(-50%);
            -webkit-transform: translateX(-50%);
            -o-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
            transform: translateX(-50%);
        }
    }
}
.page-account{
    .heading{
        &.sub-heading{
            font-size: em(16);
        }
    }
    .account-skill-list{
        li{
            font-size: em(14);
            background-color: #f9fafc;
            padding: 16px 23px;
            line-height: 18px;
            font-weight: $font-weight-semibold;
            color: $heading-color;
            @include media-breakpoint-down(xs) {
                width:100%;
                margin:1em 0 0 0 !important;
            }
        }
    }
}
.page-family-detail{
    .section{
        padding: 80px 0;
    }
    .heading{
        font-size: em(24);
    }
}
.nannies-contact-info{
    .contact-info-item{
        margin-bottom: 45px;
        @include media-breakpoint-down(sm) {
            margin-bottom: 2rem;
        }
        &.b-right{
            border-right: 1px solid rgba($black, 0.1);
            @include media-breakpoint-down(md) {
                border-right:0;
            }
        }
        line-height: 20px;
        span{
            display: block;
            padding-bottom: 5px;
        }
        strong{
            color: #676767;
            font-weight: $font-weight-bold;
        }
    }
}